<template>
  <div>
    <v-card class="mx-auto">
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">list_alt</v-icon>
        <v-toolbar-title>User Profile</v-toolbar-title>
      </v-app-bar>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="user.name"
            label="Name"
            v-validate="'required'"
            required
            data-vv-name="name"
            :error-messages="errors.collect('name')"
          ></v-text-field>

          <v-text-field
            v-model="user.email"
            label="E-mail"
            v-validate="'required|email'"
            required
            data-vv-name="email"
            :error-messages="errors.collect('email')"
          ></v-text-field>

          <v-text-field
            :prefix="user.countryCode"
            v-model="user.mobileNumber"
            label="Mobile Number"
            data-vv-name="MobileNumber"
            :error-messages="errors.collect('MobileNumber')"
            v-validate="'required|numeric'"
          ></v-text-field>

          <v-text-field v-if="organization.name"
            v-model="organization.name"
            label="Organization" 
            readonly
          ></v-text-field>
        </v-form>

        <v-btn dark @click="updateUser" color="primary">Update</v-btn>
      </v-card-text>
    </v-card>
    
  </div>
</template>

<script>
import DataServiceVD from "./../services/dataServiceVD.js";
const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      valid: true,
      user: this.$store.state.userProfile,
      readonly: true,
      organization: {},
    };
  },
  mounted() {
    this.getOrgName();
  },
  methods: {
    getOrgName() {
      if (this.$store.state.vendorUser.organizationId) {
        dataServiceVD.getOrganization({ _id: this.$store.state.vendorUser.organizationId }).then(res => {
          this.organization = res.data
        });
      }
    },
    async updateUser() {
      let v = await this.$validator.validateAll();

      if (v) {
        this.$setLoader();

        dataServiceVD.updateUser({
          name: this.user.name,
          email: this.user.email,
          mobileNumber: this.user.mobileNumber,
          userId: this.user._id
        }).then(res => {
          this.showDialogMessage = true
          this.dialogMessageTitle = "Changes Successfully"
          this.dialogMessageText = res.data.message
          this.$disableLoader();
        }).catch(error => {
          this.showDialogMessage = true
          this.dialogMessageTitle = "Error"
          this.dialogMessageText = error
          this.$disableLoader();
        });
      }
    }
  }
};
</script>
